<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row >
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Seguimiento Proyectos</span>
	  		    <v-spacer></v-spacer>

            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( filterSeguimiento  , 'PROYECTOS')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
            <v-row>
              <v-col cols="12" md="3">
                <v-radio-group
                  v-model="opEstatus"
                  row
                  hide-details
                >
                  <v-radio
                    label="Activos"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Inactivos"
                    :value="0"
                  ></v-radio>
                  <v-radio
                    label="Todos"
                    :value="3"
                  ></v-radio>
                  <v-radio
                    label="Entregados"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" md="3">
                
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="filterSeguimiento"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
                  item-class="clase_row"
							  >

                  <template v-slot:item.estatus="{ item }">
                    <v-chip color="green" v-if="item.estatus == 1" small dark >Activo</v-chip>
                    <v-chip color="red"   v-if="item.estatus == 0" small dark >Inactivo</v-chip>
                    <v-chip color="blue"  v-if="item.estatus == 2" small dark >Entregado</v-chip>
                  </template>

                  <template v-slot:item.contacto_cliente="{ item }">
                    <v-chip color="green" v-if="item.contacto_cliente == 1" small dark >Si</v-chip>
                    <v-chip color="grey"   v-if="item.contacto_cliente == 0" small dark >No</v-chip>
                  </template>

                  <template v-slot:item.cantidad_actividades="{ item }">
                    <v-chip color="green" small dark @click="verActividades( item.idproyectos_ti, 1, item.proyecto, item.cliente )">{{ item.cantidad_actividades }}</v-chip>
                  </template>

                  <template v-slot:item.actividades_realizadas="{ item }">
                    <v-chip color="green" small dark @click="verActividades( item.idproyectos_ti, 2, item.proyecto, item.cliente )">{{ item.actividades_realizadas }}</v-chip>
                  </template>

                  <template v-slot:item.avance="{ item }">
                    <v-chip color="green" small dark >{{ item.avance }}</v-chip>
                  </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
                			v-if="[71,72,64,68,69,76,77,63,31,11].includes( getdatosUsuario.idpuesto )"
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small

							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idproyectos_ti }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12">
              <v-text-field
                v-model="editedItem.proyecto"
                filled
                dense
                hide-details
                label="Nombre del proyecto"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.alcance"
                filled
                dense
                hide-details
                label="Alcance del proyecto"
                :rows="3"
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12">
	  		  		<v-autocomplete 
	  		  			filled 
	  		  			dense 
	  		  			clearable 
	  		  			v-model="editedItem.id_cliente" 
	  		  			:items="usuarios"
								label="Selecciona un usuario" 
								hide-details
								item-text="datos_completos" 
								item-value="id_usuario"
							>
							</v-autocomplete>
  		  		</v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.prioridad"
                filled
                dense
                hide-details
                label="Prioridad"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-checkbox
                v-model="editedItem.contacto_cliente"
                label="Contacto con el cliente"
              ></v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-radio-group
                v-model="editedItem.estatus"
                row
                hide-details
              >
                <v-radio
                  label="Activo"
                  :value="1"
                ></v-radio>
                <v-radio
                  label="Inactivos"
                  :value="0"
                ></v-radio>
                 <v-radio
                  label="Entregado"
                  :value="2"
                ></v-radio>
              </v-radio-group>
            </v-col>
            
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


	  <!-- Dialogo para ver y agregar el detalle -->
	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialogDetalles"
      max-width="800px"
      persistent
    >
      <v-card>
        <v-card-title>
        	<div>
          	<span class="text-subtitle-1">Actividades - <b>{{ proyecto }}</b></span>
          	<br/>
          	<span class="text-subtitle-1">Cliente: <b>{{ cliente }}</b></span>
        	</div>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idproyectos_ti }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12" md="10">
              <v-text-field
                v-model="actividad"
                filled
                dense
                hide-details
                label="Actividad"
                @keyup.enter="addDetalle( )"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn color="orange">
              	<v-icon tile dark @click="addDetalle( )">mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
						  <v-data-table
						    :headers="headersDetalle"
						    :items="detalles"
						    class="elevation-0"
						    :mobile-breakpoint="100"
		            dense
                item-class="clase_row"
						  >

						  	<template v-slot:item.realizada="{ item }">
						    	<v-checkbox 
						    		v-model="item.realizada" 
						    		:value="1"
						    		@change="updateActividad( item ) "
						    		hide-details
						    		small
						    		class="ma-0"
						    	></v-checkbox>
						    </template>

						    <template v-slot:item.actions="{ item }">
						    	<v-btn 
						    		color="error" 
						    		x-small
						    		@click="eliminarActividad(item)"
						    		class="mr-2"
						    	>
							      <v-icon small> mdi-delete</v-icon>
						    	</v-btn>
						    </template>

                <template v-slot:item.actividad="props">
                  <v-edit-dialog
                    large
                    :return-value.sync="props.item.actividad"
                    @save="updateActividad( props.item )"
                    save-text="Guardar"
                    cancel-text="cancelar"
                  >
                    {{ props.item.actividad }}
                    <template v-slot:input>
                      <v-text-field 
                        class="mt-2"
                        v-model="props.item.actividad"
                        label="Actividad"
                        single-line
                        filled
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>


                <template v-slot:item.fecha_entrega="props">
                  <v-edit-dialog
                    large
                    :return-value.sync="props.item.fecha_entrega"
                    @save="updateActividad( props.item )"
                    save-text="Guardar"
                    cancel-text="cancelar"
                  >
                    {{ props.item.fecha_entrega }}
                    <template v-slot:input>
                      <v-text-field 
                        class="mt-2"
                        v-model="props.item.fecha_entrega"
                        label="Fecha Entrega"
                        single-line
                        type="date"
                        filled
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>


						  </v-data-table>
  		    	</v-col>

            
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="dialogDetalles = false"
          >
            <v-icon small left>mdi-close</v-icon>
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      id_puesto:0,

      editedItem: {
        idproyectos_ti: 0,
				proyecto: 0,
				alcance: '',
				prioridad: 0,
				contacto_cliente: 0,
				id_cliente: '',
				estatus: 0,
				deleted:0,
				fecha_creacion: null,
      },

      defaultItem: {
        idproyectos_ti: 0,
				proyecto: 0,
				alcance: '',
				prioridad: 0,
				contacto_cliente: 0,
				id_cliente: '',
				estatus: 0,
				deleted:0,
				fecha_creacion: null,
      },

      // Filtros: 
      incio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fin  :(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      salones: [],
      headers: [
        { text: 'ID'                   , value: 'idproyectos_ti'            },
        { text: 'Proyecto  '           , value: 'proyecto'                  },
        { text: 'Alcance'              , value: 'alcance'                   },
        { text: '# Actividades'        , value: 'cantidad_actividades'      },
        { text: '# Actividades hechas' , value: 'actividades_realizadas'    },
        { text: '% avance'             , value: 'avance'                    },
        { text: 'Contacto cliente'     , value: 'contacto_cliente'          },
        { text: 'Prioridad'            , value: 'prioridad'                 },
        { text: 'Cliente'              , value: 'cliente'                   },
        { text: 'Estatus'              , value: 'estatus'                   },
        { text: 'Actions'              , value: 'actions'                   },
      ],

      proyectos: [],
      puestos: [],
      turnos:[],
      planteles:[],
      filtroEstatus: 0,
      filtroFechaIni: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      filtroFechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      opEstatus: 1,

      detalles:[],
      fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      resultado: 0,
      id_plantel:0,
      puestosAll:[],
      usuarios:[],
      idproyectos_ti: null,
      actividad:'',
			realizada:0,
      dialogDetalles:false,

      headersDetalle:[
        { text: 'Actividad'     , value: 'actividad'     },
        { text: 'Fecha Entrega' , value: 'fecha_entrega' },
        { text: 'Realizada'     , value: 'realizada'     },
        { text: 'Actions'       , value: 'actions', sortable: false },
      ],
      proyecto:'',
      cliente:''
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar proyecto' : 'Editar proyecto'
      },

      filterSeguimiento(){

        let data = this.proyectos

        if( [0,1,2].includes( this.opEstatus ) ){
          data = data.filter( el => el.estatus == this.opEstatus )
        }

        return data 
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
      await this.getTurnos()
      await this.getUsuarios( )
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.proyectos = []

        return this.$http.get('seguimiento.proyectos' ).then(response=>{
        	this.proyectos = response.data

          this.puestos = this.proyectos.map((registo) => { return { idpuesto: registo.id_puesto, puesto: registo.puesto } })
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestosAll = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false

          this.puestosAll = response.data 
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getTurnos( ){
        this.cargar = true
        this.turnos = []
        this.$http.get('seguimiento.turnos').then(response=>{
          this.turnos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      editItem (item) {
        this.editedIndex = this.proyectos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase() 
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      deleteItem (item) {
        this.editedIndex = this.proyectos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1

        this.$http.put('seguimiento.proyectos.update/' + this.editedItem.idproyectos_ti, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if( !this.editedItem.proyecto || !this.editedItem.prioridad || !this.editedItem.id_cliente ){
      		return this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('seguimiento.proyectos.update/' + this.editedItem.idproyectos_ti, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
        		this.close()
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('seguimiento.proyectos.add', this.editedItem).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      saveResultado( ){

        let tipo = 2
        if( [71,72,64,68,69,76,77,63,31].includes( this.getdatosUsuario.idpuesto ) ){ tipo = 1 }

        let payload = {
          fecha     : this.fecha,
          valor     : this.resultado,
          rh_mkt    : tipo,
          id_usuario: this.getdatosUsuario.iderp,
          idproyectos_ti: this.editedItem.idproyectos_ti
        }

        let valor = Object.assign({}, this.editedItem )

        // Lo mandapos por el EP
        this.$http.post('seguimiento.proyectos.detalle.add', payload).then(response=>{
          this.resultado = 0
          this.close( )
          this.initialize( )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      verActividades( idproyectos_ti, tipo, proyecto, cliente ){

      	this.idproyectos_ti = idproyectos_ti
      	this.proyecto       = proyecto
      	this.tipo           = tipo
      	this.cliente        = cliente


      	this.detalles = []

      	this.$http.get('seguimiento.proyectos.id/' + this.idproyectos_ti).then(response=>{
        	this.cargar = true
        	this.detalles = response.data

        	if( this.tipo == 2 ){

        		this.detalles = this.detalles.filter( el => el.realizada )

        	}
        	this.dialogDetalles = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      addDetalle( ){

        let payload = {
          idproyectos_ti : this.idproyectos_ti,
          actividad      : this.actividad,
          realizada      : this.realizada,
        }

        let actividad = Object.assign({}, this.editedItem )

        // Lo mandapos por el EP
        this.$http.post('seguimiento.proyectos.detalle.add', payload).then(response=>{
          this.resultado = 0
          this.actividad = ''
          this.verActividades( this.idproyectos_ti, this.tipo, this.proyecto, this.cliente )
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      updateActividad( item ){
      	this.cargar = true

      	this.$http.put('seguimiento.proyectos.detalle.update/' + item.idproyectos_actividades, item).then(response=>{
        	this.validarSuccess( response.data.message )
          this.verActividades( this.idproyectos_ti, this.tipo, this.proyecto, this.cliente )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      eliminarActividad( item ){
        this.cargar = true
        item.deleted = 1
        this.$http.put('seguimiento.proyectos.detalle.update/' + item.idproyectos_actividades, item).then(response=>{
          this.validarSuccess( response.data.message )
          this.verActividades( this.idproyectos_ti, this.tipo, this.proyecto, this.cliente )
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

    },
  }
</script>
falta_campania

<style>
  .falta_campania{
    background-color: #FF8F8F !important;
    color: white;
  }
</style>

